
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import PeriodToggle from './period-toggle.vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';
import {deepFreeze} from '@reedsy/utils.object';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import {ISubscriptionFeaturesModalArgs} from './subscription-features-modal-args.interface';
import {getProducts} from '@reedsy/utils.subscription';

@Component({
  components: {
    AnnualSaving,
    FeatureSummary,
    PeriodToggle,
    SubscriptionBaseModal,
  },
})
export default class SubscriptionFeatures extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionFeaturesModalArgs>, default: {}})
  public context: ISubscriptionFeaturesModalArgs;

  public readonly cancelable = true;
  public billAnnually = false;
  public title = 'Ready to upgrade?';

  public readonly summaries: ReadonlyArray<IProductSummary> = deepFreeze([
    {
      product: null,
      title: 'Basic',
      features: [
        '30-day history', // TODO: [subscriptions] update?
        'Basic stats',
        'One manuscript Goal', // TODO: [subscriptions] implement?
        'Writing features',
        'Typeset to PDF and EPUB',
        'Planning Boards limited to card view', // TODO: [subscriptions] check?
        'Unlimited devices',
      ],
    },
    {
      product: 'writing',
      title: 'Studio Pro',
      features: [
        '⏳ Unlimited history',
        '📊 Advanced stats', // TODO: [subscriptions] check?
        '🎯 Custom goals',
        '☑️ Daily check-ins',
        '🌒 Dark mode',
      ],
    },
    {
      product: 'outlining',
      title: 'Outlining',
      features: [
        '♾️ Unlimited Boards & Notes',
        '📋 Notes with Attributes',
        '📌 Pinned notes', // TODO: [subscriptions] implement?
        '🌒 Dark mode',
        '👀 List view',
      ],
    },
  ]);

  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>> = {};

  public get hasSelectedAnything(): boolean {
    return Object.values(this.selectedProducts).some(Boolean);
  }

  public created(): void {
    const feature = this.context.feature;
    if (!feature) return;
    this.title = 'Upgrade to access this feature!';
    for (const product of getProducts(feature)) this.selectedProducts[product] = true;
  }
}
