<template>
  <component
    :is="isProduct ? 'button' : 'div'"
    class="feature-summary reedsy-accented"
    :class="{
      product: isProduct,
      'vui-tab-option': isProduct, // Reuse some styling but without the full component
      active: isProduct && selected,
    }"
    @click="toggleSelected"
  >
    <h2>
      {{ summary.title }}
      <!-- TODO: [subscriptions] add pricing -->
    </h2>

    <ul>
      <li
        v-for="feature in summary.features"
        :key="feature"
      >
        {{ feature }}
      </li>
    </ul>

    <button
      v-if="isProduct"
      type="button"
      class="button button-full reedsy-accented"
      :class="{
        bordered: !selected,
      }"
    >
      {{ selected ? 'Add-on selected' : 'Select add-on' }}
    </button>

    <VuiIconTick
      v-if="isProduct"
      class="icon-tick reedsy-accented plain"
    />
  </component>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';

@Component({})
export default class FeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }

  public toggleSelected(): void {
    if (!this.isProduct) return;
    this.selected = !this.selected;
  }
}
</script>

<style lang="scss" scoped>
.feature-summary {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: $border-radius-lg;
  padding: $space-base;
  margin: 0;

  &.product {
    border: $border-width-lg solid var(--reedsy-neutral);

    &.active,
    &:hover {
      border: $border-width-lg solid var(--local-color-default);
    }

    &:hover {
      --local-color-default: var(--local-color-emphasis);
    }
  }

  &:not(.product),
  &.active {
    box-shadow: $box-shadow-base;
  }

  ul {
    padding: 0;
    flex: 1 0 auto;

    li {
      @include font-family($controls);

      margin: $space-xs;
    }
  }
}
</style>
