import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DelayedLoadingIndicator = _resolveComponent("DelayedLoadingIndicator")!
  const _component_AddOnToggle = _resolveComponent("AddOnToggle")!
  const _component_VuiCheckbox = _resolveComponent("VuiCheckbox")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (!_ctx.currencies)
    ? (_openBlock(), _createElementBlock("rbe-loading", _hoisted_1, [
        _createVNode(_component_DelayedLoadingIndicator, { delay: 1_000 })
      ]))
    : (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
        key: 1,
        id: _ctx.id,
        title: "Ready to go premium?",
        class: "subscription-trial-modal"
      }, {
        left: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "\n        Unlock the full power of Reedsy Studio with our powerful add-ons.\n      ", -1)),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_AddOnToggle, {
            title: "Studio Pro",
            "model-value": true,
            disabled: true
          }),
          _cache[3] || (_cache[3] = _createTextVNode()),
          _createVNode(_component_AddOnToggle, {
            title: "Outlining",
            "model-value": true,
            disabled: true
          }),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checklistItems, ({text}, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass({
            active: index === _ctx.activeCheckListIndex,
          })
              }, [
                _createElementVNode("button", {
                  class: "button-full reedsy-accented",
                  onClick: ($event: any) => (_ctx.activeCheckListIndex = index)
                }, [
                  _createVNode(_component_VuiCheckbox, {
                    label: text,
                    readonly: true,
                    value: true
                  }, null, 8, ["label"])
                ], 8, _hoisted_2)
              ], 2))
            }), 128))
          ]),
          _cache[5] || (_cache[5] = _createTextVNode()),
          _createVNode(_component_LoadingButton, {
            class: "button",
            type: "button",
            loading: _ctx.loading,
            onClick: _ctx.startTrial
          }, {
            default: _withCtx(() => [
              _createTextVNode("\n        Start " + _toDisplayString(_ctx.config.stripe.trialDays) + " day trial\n      ", 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"]),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("button", {
            class: "button-subtle",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, "\n        No thanks, not yet!\n      ")
        ]),
        right: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("\n      TODO: [subscriptions] Add videos\n    ")
        ])),
        _: 1
      }, 8, ["id"]))
}