<template>
  <EntryAction class="entry-action flex-top">
    <rbe-tooltip
      v-tooltip.top="restoreButtonTooltip"
      class="full-width"
    >
      <button
        class="
          button
          button-md
          full-width
          reedsy-accented
          restore-book-button
        "
        type="button"
        :aria-label="`Restore book ${book.title}`"
        :disabled="!isUserBookOwner"
        @click="openRestoreBookModal"
      >
        Restore
      </button>
    </rbe-tooltip>
    <a
      class="
        button
        button-md
        full-width
        reedsy-accented
        neutral
        open-in-editor-button
      "
      :href="bookEditorLink"
      target="_blank"
      :aria-label="`Continue writing ${book.title}`"
    >
      Open
    </a>
  </EntryAction>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import EntryAction from './entry-action.vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({
  components: {
    EntryAction,
  },
})
export default class ArchivedBookAction extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @Prop({type: Object, required: true})
  public book: IBookshelfBook;

  public get isUserBookOwner(): boolean {
    return this.book.ownerId === this.$user.id;
  }

  public get restoreButtonTooltip(): string {
    return this.isUserBookOwner ? undefined : 'Only the book owner can restore the book';
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }

  public openRestoreBookModal(): void {
    this.$modals.OPEN({
      type: 'ConfirmBookArchive',
      context: {
        bookId: this.book.id,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.entry-action {
  justify-content: space-between;
  align-items: normal;
  gap: $space-sm;
  padding: $space-md;
  width: calc(100% - $cover-placeholder-spine-width);
  margin-left: $cover-placeholder-spine-width;

  .full-width {
    width: 100%;
  }
}
</style>
