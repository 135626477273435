import {injectable, named} from 'inversify';
import {ISubscriptionModalService} from './i-subscription-modal-service';
import {$inject} from '@reedsy/studio.shared/types';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {IPaidFeature} from '@reedsy/utils.subscription';

@injectable()
export class SubscriptionModalService implements ISubscriptionModalService {
  @$inject('StoreModule')
  @named(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public $user: SharedUserModule;

  // TODO: [subscriptions] open payment modal if trialling
  // TODO: [subscriptions] open payment modal if trial expired
  public open(): Promise<void> {
    return this.$modals.OPEN('SubscriptionTrial');
  }

  public openFeatures(feature?: IPaidFeature): Promise<void> {
    return this.$modals.OPEN({
      type: 'SubscriptionFeatures',
      context: {feature},
    });
  }
}
